
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React from "react";

import {
	FireballThemeProvider,
	prosjektpluss,
} from "@schibsted-smb/fireball-themes";
import type { AppProps } from "next/app";

import type { NextPageWithLayout } from "~types/NextPageWithLayout";
import { Bugsnag } from "~utils/bugsnag";

import "../public/static/style/LiteYoutubeEmbed.css";
import "../public/static/style/SwiperNavigation.css";

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

// Only `const __Page_Next_Translate__ = function` because of https://github.com/aralroca/next-translate/issues/486, should be fixed in 2.0 of next-translate
const __Page_Next_Translate__ = function App({ Component, pageProps }: AppPropsWithLayout) {
	const getLayout = Component.getLayout ?? ((page) => page);

	return (
		<ErrorBoundary>
			<FireballThemeProvider theme={prosjektpluss}>
				{getLayout(<Component {...pageProps} />)}
			</FireballThemeProvider>
		</ErrorBoundary>
	);
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  